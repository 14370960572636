import React, { useEffect, useState } from 'react';
import { FileUpload, InvoiceDisplay, ValidationResults, Footer } from './components/exports';
import { XRechnungData } from './types/xrechnung';
import { ValidationResult } from './types/validation';
import { useTranslation } from './contexts/TranslationContext';
import { FileCheck } from 'lucide-react';
import { Helmet } from 'react-helmet';
import CookieConsent from 'react-cookie-consent';

function App() {
  const [file, setFile] = useState<File | null>(null);
  const [validationStatus, setValidationStatus] = useState<'idle' | 'validating' | 'validated' | 'error'>('idle');
  const [invoice, setInvoice] = useState<XRechnungData | null>(null);
  const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    // Track page view
    if (window.gtag) {
      window.gtag('config', 'G-CYZX68VXPN', {
        page_path: window.location.pathname,
      });
    }
  }, []);

  const handleFileUpload = async (data: XRechnungData, validation: ValidationResult) => {
    setValidationStatus('validating');
    
    try {
      if (!validation.isValid) {
        setValidationStatus('error');
        setValidationResult({
          isValid: false,
          errors: validation.errors || [],
          warnings: []
        });
        return;
      }

      setInvoice(data);
      setValidationStatus('validated');
      setValidationResult({
        isValid: true,
        errors: [],
        warnings: validation.warnings || []
      });
    } catch (error) {
      console.error('Error processing file:', error);
      setValidationStatus('error');
      setValidationResult({
        isValid: false,
        errors: [{
          code: 'PROCESSING_ERROR',
          message: t('error.PROCESSING_ERROR'),
          location: 'document',
          severity: 'CRITICAL'
        }],
        warnings: []
      });
    }
  };

  const handleReset = () => {
    setFile(null);
    setValidationStatus('idle');
    setInvoice(null);
    setValidationResult(null);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Helmet>
        <title>XRechnung Viewer - Robaws</title>
        <meta name="description" content="XRechnung Viewer and Validator" />
        <meta name="keywords" content="XRechnung, Viewer, Validator, Rechnungen" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebApplication",
              "name": "XRechnung Viewer",
              "description": "A tool for viewing and validating XRechnung invoices.",
              "url": "https://xrechnungzentrale.de",
              "applicationCategory": "BusinessApplication",
              "operatingSystem": "All",
              "author": {
                "@type": "Organization",
                "name": "Robaws"
              }
            }
          `}
        </script>
      </Helmet>
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8 flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <FileCheck className="h-8 w-8 text-blue-600" />
            <h1 className="text-2xl font-semibold text-gray-900">XRechnung Viewer</h1>
          </div>
          <div className="flex items-center space-x-4 ml-auto">
            <a href="https://robaws.com/de" target="_blank" rel="noopener noreferrer" className="text-sm text-gray-500 hover:text-gray-700">Mehr über Robaws</a>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        <div className="space-y-8">
          <section className="bg-white rounded-lg shadow-sm p-6">
            <div className="max-w-3xl mx-auto">
              <div className="flex flex-col items-center mb-6">
                <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-6">Ihre Eingangsrechnungen Effizient Verwalten</h1>
                <p className="text-lg text-gray-600 max-w-3xl mx-auto">Suchen Sie nach einer Lösung, um Eingangsrechnungen schneller zu verarbeiten? Oder haben Sie wieder eine XRechnung von Ihrem Lieferanten erhalten und wissen nicht, was damit zu tun ist?</p>
                {validationResult && (
                  <button
                    onClick={handleReset}
                    className="px-4 py-2 text-sm font-medium text-blue-600 bg-blue-50 rounded-md hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    weitere XRechnung hochladen
                  </button>
                )}
              </div>
              {!validationResult && <FileUpload onFileProcessed={handleFileUpload} />}
            </div>
          </section>

          <CookieConsent
            location="bottom"
            buttonText="Zustimmen"
            cookieName="XRechnung Viewer Cookies"
            style={{ background: "#1F2937", color: "#FFFFFF", borderTop: "1px solid #4B5563" }}
            buttonStyle={{ color: "#FFFFFF", backgroundColor: "#3B82F6", fontSize: "14px", padding: "10px 20px", borderRadius: "5px", transition: "background-color 0.3s" }}
            declineButtonText="Ablehnen"
            enableDeclineButton
            onDecline={() => {
              console.log("Cookies abgelehnt");
            }}
            buttonWrapperClasses="flex justify-center"
          >
            Diese Website verwendet Cookies, um Ihnen das beste Erlebnis zu bieten.  
            <a href="/datenschutz" style={{ color: "#3B82F6", textDecoration: "underline" }}>Mehr erfahren</a>
          </CookieConsent>

          {validationStatus === 'validating' && (
            <div className="flex items-center justify-center p-4 bg-blue-50 rounded-lg">
              <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-600 mr-3"></div>
              <span className="text-blue-600">{t('processingFile')}</span>
            </div>
          )}

          {invoice && validationResult && (
            <div className="lg:flex lg:gap-4">
              <div className="lg:w-3/4 mb-8 lg:mb-0">
                <InvoiceDisplay invoice={invoice} />
              </div>
              <div className="lg:w-1/4 w-full">
                <ValidationResults result={validationResult} />
              </div>
            </div>
          )}
          <section className="py-16 bg-white rounded-xl shadow-sm">
            <div className="max-w-4xl mx-auto px-4">
              <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
                Automatisieren Sie Ihre Rechnungsverarbeitung
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="text-center">
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    className="lucide lucide-credit-card w-12 h-12 text-blue-500 mx-auto mb-4"
                  >
                    <rect width="20" height="14" x="2" y="5" rx="2"></rect>
                    <line x1="2" x2="22" y1="10" y2="10"></line>
                  </svg>
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">
                    Rechnungen direkt bezahlen
                  </h3>
                  <p className="text-gray-600">
                    Automatisieren Sie Ihre Zahlungsprozesse und sparen Sie wertvolle Zeit.
                  </p>
                </div>

                <div className="text-center">
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    className="lucide lucide-users w-12 h-12 text-blue-500 mx-auto mb-4"
                  >
                    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">
                    Steuerberater informieren
                  </h3>
                  <p className="text-gray-600">
                    Nahtlose Integration mit Ihrem Buchhaltungsteam für effiziente Zusammenarbeit.
                  </p>
                </div>

                <div className="text-center">
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    className="lucide lucide-clipboard-list w-12 h-12 text-blue-500 mx-auto mb-4"
                  >
                    <rect width="8" height="4" x="8" y="2" rx="1" ry="1"></rect>
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                    <path d="M12 11h4"></path>
                    <path d="M12 16h4"></path>
                    <path d="M8 11h.01"></path>
                    <path d="M8 16h.01"></path>
                  </svg>
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">
                    Bestellungen verwalten
                  </h3>
                  <p className="text-gray-600">
                    Verwalten Sie Ihre Angebote, Bestellungen, Projekte, Aufträge und Rechnungen in einem System.
                  </p>
                </div>
              </div>

              <div className="mt-12 text-center">
                <a 
                  href="https://robaws.de" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="inline-flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white font-medium px-8 py-3 rounded-lg transition-colors"
                >
                  Jetzt Robaws entdecken
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    className="lucide lucide-arrow-right w-5 h-5"
                  >
                    <path d="M5 12h14"></path>
                    <path d="m12 5 7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
          </section>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default App;