import { 
  XRechnungData, 
  Attachment,
  Party,
  Address,
  Contact,
  BankAccount,
  PaymentMeans,
  PaymentTerms,
  InvoiceItem
} from '../types';

export class XRechnungParser {
  private xmlDoc: Document;
  private namespaces: {[key: string]: string};
  private documentType: string;
  private readonly LINE_TYPES = ['InvoiceLine', 'CreditNoteLine'] as const;
  
  constructor(xmlContent: string) {
    const parser = new DOMParser();
    this.xmlDoc = parser.parseFromString(xmlContent, 'application/xml');
    this.namespaces = this.extractNamespaces();
    this.documentType = this.determineDocumentType();
  }

  private extractNamespaces(): {[key: string]: string} {
    const root = this.xmlDoc.documentElement;
    const namespaces: {[key: string]: string} = {};
    
    // Extract all namespace declarations
    Array.from(root.attributes).forEach(attr => {
      if (attr.name.startsWith('xmlns:')) {
        const prefix = attr.name.split(':')[1];
        namespaces[prefix] = attr.value;
      } else if (attr.name === 'xmlns') {
        namespaces['default'] = attr.value;
      }
    });
    
    // Handle special case for ubl namespace
    if (namespaces['ubl']) {
      namespaces['default'] = namespaces['ubl'];
    }
    
    return namespaces;
  }

  private determineDocumentType(): string {
    const root = this.xmlDoc.documentElement;
    const localName = root.localName || root.tagName.split(':').pop();
    
    // Handle both prefixed and non-prefixed root elements
    if ((localName === 'Invoice' || root.tagName === 'ubl:Invoice') && 
        (root.namespaceURI === this.namespaces.default || 
         root.namespaceURI === this.namespaces.ubl)) {
      return 'Invoice';
    }
    
    if ((localName === 'CreditNote' || root.tagName === 'ubl:CreditNote') && 
        (root.namespaceURI === this.namespaces.default || 
         root.namespaceURI === this.namespaces.ubl)) {
      return 'CreditNote';
    }
    
    throw new Error('Unknown document type');
  }

  public parse(): XRechnungData {
    return {
      id: this.getElementValue('ID'),
      documentType: this.documentType,
      invoiceNumber: this.getElementValue('ID'),
      issueDate: this.getElementValue('IssueDate'),
      dueDate: this.getElementValue('DueDate'),
      invoiceTypeCode: this.getElementValue('InvoiceTypeCode'),
      buyerReference: this.getElementValue('BuyerReference'),
      currencyCode: this.getElementValue('DocumentCurrencyCode'),
      notes: this.getNotes(),
      paymentMeans: this.parsePaymentMeans(),
      seller: this.parseSeller(),
      buyer: this.parseBuyer(),
      items: this.parseLineItems(),
      ...this.parseTotals(),
      delivery: this.parseDelivery(),
      attachments: this.parseAttachments(),
      precedingInvoiceReference: this.getElementValue('PrecedingInvoiceReference'),
      contractReference: this.getElementValue('ContractDocumentReference/ID'),
      projectReference: this.getElementValue('ProjectReference/ID')
    };
  }

  private getElementValue(tagName: string): string {
    const element = this.xmlDoc.getElementsByTagName(`cbc:${tagName}`)[0];
    return element?.textContent?.trim() || '';
  }

  private parsePaymentMeans(): PaymentMeans | undefined {
    const paymentMeans = this.xmlDoc.getElementsByTagName('cac:PaymentMeans')[0];
    if (!paymentMeans) return undefined;

    return {
      type: this.getPaymentType(paymentMeans),
      paymentMeansCode: this.getElementFromNode(paymentMeans, 'PaymentMeansCode'),
      paymentID: this.getElementFromNode(paymentMeans, 'InstructionID'),
      bankAccount: this.parseBankAccount(paymentMeans)
    };
  }

  private getPaymentType(paymentMeans: Element): string {
    const code = this.getElementFromNode(paymentMeans, 'PaymentMeansCode');
    // Map payment means codes to readable types
    const types: { [key: string]: string } = {
      '1': 'Barzahlung',
      '42': 'Banküberweisung',
      '48': 'Kreditkarte',
      '49': 'Lastschrift'
    };
    return types[code] || code;
  }

  private parseBankAccount(paymentMeans: Element): BankAccount | undefined {
    const financialAccount = paymentMeans.getElementsByTagName('cac:PayeeFinancialAccount')[0];
    if (!financialAccount) return undefined;

    return {
      iban: this.getElementFromNode(financialAccount, 'ID'),
      bic: this.getElementFromNode(financialAccount, 'FinancialInstitutionBranch/ID'),
      bankName: this.getElementFromNode(financialAccount, 'FinancialInstitution/Name')
    };
  }

  private parseSeller(): Party {
    return this.parseParty('AccountingSupplierParty');
  }

  private parseBuyer() {
    return this.parseParty('AccountingCustomerParty');
  }

  private parseParty(partyType: 'AccountingSupplierParty' | 'AccountingCustomerParty'): Party {
    const partyElement = this.xmlDoc.getElementsByTagName(`cac:${partyType}`)[0];
    if (!partyElement) return this.createEmptyParty();
    
    const party = partyElement.getElementsByTagName('cac:Party')[0];
    if (!party) return this.createEmptyParty();

    return {
      name: this.getElementFromNode(party, 'PartyName/Name') || 
            this.getElementFromNode(party, 'PartyLegalEntity/RegistrationName'),
      address: this.parseAddress(party),
      vatNumber: this.getElementFromNode(party, 'PartyTaxScheme/CompanyID'),
      contact: this.parseContact(party)
    };
  }

  private parseAddress(party: Element) {
    const address = party.getElementsByTagName('cac:PostalAddress')[0];
    if (!address) return this.createEmptyAddress();

    return {
      street: this.getElementFromNode(address, 'StreetName'),
      city: this.getElementFromNode(address, 'CityName'),
      postcode: this.getElementFromNode(address, 'PostalZone'),
      country: this.getElementFromNode(address, 'Country/Name'),
      countryCode: this.getElementFromNode(address, 'Country/IdentificationCode')
    };
  }

  private parseContact(party: Element) {
    const contact = party.getElementsByTagName('cac:Contact')[0];
    if (!contact) return undefined;

    return {
      name: this.getElementValue('Name'),
      phone: this.getElementValue('Telephone'),
      email: this.getElementValue('ElectronicMail')
    };
  }

  private parseLineItems(): InvoiceItem[] {
    const lines = this.getLineItems();
    return lines.map(line => ({
      id: this.getElementFromNode(line, 'ID'),
      description: this.getElementFromNode(line, 'Item/Name'),
      quantity: parseFloat(this.getElementFromNode(line, this.documentType === 'Invoice' ? 'InvoicedQuantity' : 'CreditedQuantity')),
      unitPrice: parseFloat(this.getElementFromNode(line, 'Price/PriceAmount')),
      lineTotal: parseFloat(this.getElementFromNode(line, 'LineExtensionAmount')),
      vatRate: parseFloat(this.getElementFromNode(line, 'Item/ClassifiedTaxCategory/Percent'))
    }));
  }

  private parseTotals() {
    const legalMonetaryTotal = this.xmlDoc.getElementsByTagName('cac:LegalMonetaryTotal')[0];
    if (!legalMonetaryTotal) return this.createEmptyTotals();

    return {
      totalAmount: parseFloat(this.getElementValue('TaxInclusiveAmount')),
      vatTotal: parseFloat(this.getElementValue('TaxTotal/TaxAmount')),
      lineExtensionAmount: parseFloat(this.getElementValue('LineExtensionAmount')),
      taxExclusiveAmount: parseFloat(this.getElementValue('TaxExclusiveAmount')),
      taxInclusiveAmount: parseFloat(this.getElementValue('TaxInclusiveAmount')),
      payableAmount: parseFloat(this.getElementValue('PayableAmount'))
    };
  }

  private parseDelivery() {
    const delivery = this.xmlDoc.getElementsByTagName('cac:Delivery')[0];
    if (!delivery) return undefined;

    return {
      deliveryDate: this.getElementValue('ActualDeliveryDate'),
      deliveryLocation: this.parseAddress(delivery)
    };
  }

  private parseAttachments(): Attachment[] {
    const attachments = this.xmlDoc.getElementsByTagName('cac:AdditionalDocumentReference');
    return Array.from(attachments).map(attachment => ({
      id: this.getElementFromNode(attachment, 'ID'),
      filename: this.getElementFromNode(attachment, 'Attachment/EmbeddedDocumentBinaryObject/@filename'),
      mimeType: this.getElementFromNode(attachment, 'Attachment/EmbeddedDocumentBinaryObject/@mimeCode'),
      description: this.getElementFromNode(attachment, 'DocumentDescription'),
      data: this.getElementFromNode(attachment, 'Attachment/EmbeddedDocumentBinaryObject'),
      content: this.getElementFromNode(attachment, 'Attachment/EmbeddedDocumentBinaryObject')
    }));
  }

  private getElementFromNode(node: Element, path: string): string {
    if (!node) return '';
    
    // Handle namespaced elements
    const parts = path.split('/');
    let current = node;
    
    for (const part of parts) {
      if (!current) return '';
      
      // Handle attribute selectors
      if (part.startsWith('@')) {
        return current.getAttribute(part.substring(1)) || '';
      }
      
      // Try with and without namespace prefixes
      const variants = [
        `cbc:${part}`,
        `cac:${part}`,
        part
      ];
      
      let found = false;
      for (const variant of variants) {
        const elements = current.getElementsByTagName(variant);
        if (elements.length > 0) {
          current = elements[0];
          found = true;
          break;
        }
      }
      
      if (!found) return '';
    }
    
    return current?.textContent?.trim() || '';
  }

  private createEmptyParty() {
    return {
      name: '',
      address: this.createEmptyAddress(),
      taxId: '',
      vatNumber: '',
      contact: undefined
    };
  }

  private createEmptyAddress() {
    return {
      street: '',
      city: '',
      postcode: '',
      country: '',
      countryCode: ''
    };
  }

  private createEmptyTotals() {
    return {
      totalAmount: 0,
      vatTotal: 0,
      lineExtensionAmount: 0,
      taxExclusiveAmount: 0,
      taxInclusiveAmount: 0,
      payableAmount: 0
    };
  }

  private parsePaymentTerms(): string {
    const terms = this.xmlDoc.getElementsByTagName('cac:PaymentTerms')[0];
    if (!terms) return '';
    
    const note = terms.getElementsByTagName('cbc:Note')[0];
    return note?.textContent || '';
  }

  private getElementByXPath(xpath: string): Element | null {
    const nsResolver = (prefix: string | null) => {
      if (!prefix) return null;
      return this.namespaces[prefix] || null;
    };
    
    const result = this.xmlDoc.evaluate(
      xpath,
      this.xmlDoc,
      nsResolver,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    );
    return result.singleNodeValue as Element;
  }

  private getNotes(): string[] {
    const notes = this.xmlDoc.getElementsByTagName('cbc:Note');
    return Array.from(notes).map(note => note.textContent?.trim() || '');
  }

  private getLineItems(): Element[] {
    for (const type of this.LINE_TYPES) {
      const lines = Array.from(this.xmlDoc.getElementsByTagNameNS(this.namespaces.cac || '', type));
      if (lines.length > 0) {
        return lines;
      }
      
      // Fallback to non-namespaced lookup
      const nonNamespacedLines = Array.from(this.xmlDoc.getElementsByTagName(`cac:${type}`));
      if (nonNamespacedLines.length > 0) {
        return nonNamespacedLines;
      }
    }
    return [];
  }
} 