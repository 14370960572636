import { ValidationError } from '../types/validation';
import { DOMParser } from 'xmldom';
import { XMLValidator } from 'fast-xml-parser';

const VALID_NAMESPACES = {
  invoice: "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2",
  creditNote: "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2"
};

export class XRechnungSchemaValidator {
  private static normalizeXml(doc: Document): Document {
    // Simple normalization without XPath
    const removeComments = (node: Node): void => {
      const childNodes = Array.from(node.childNodes);
      childNodes.forEach(child => {
        if (child.nodeType === 8) { // Comment node
          node.removeChild(child);
        } else if (child.hasChildNodes()) {
          removeComments(child);
        }
      });
    };

    removeComments(doc);
    return doc;
  }

  private static isValidXRechnung(doc: Document): boolean {
    // Check for Invoice with or without prefix
    const invoiceNodes = Array.from(doc.getElementsByTagNameNS(VALID_NAMESPACES.invoice, 'Invoice'))
      .concat(Array.from(doc.getElementsByTagName('ubl:Invoice')));
    const hasInvoice = invoiceNodes.some(node => 
      node.namespaceURI === VALID_NAMESPACES.invoice || 
      node.lookupNamespaceURI('ubl') === VALID_NAMESPACES.invoice
    );
    
    // Check for CreditNote with or without prefix
    const creditNoteNodes = Array.from(doc.getElementsByTagNameNS(VALID_NAMESPACES.creditNote, 'CreditNote'))
      .concat(Array.from(doc.getElementsByTagName('ubl:CreditNote')));
    const hasCreditNote = creditNoteNodes.some(node => 
      node.namespaceURI === VALID_NAMESPACES.creditNote || 
      node.lookupNamespaceURI('ubl') === VALID_NAMESPACES.creditNote
    );

    return hasInvoice || hasCreditNote;
  }

  private static async getSchema(): Promise<string> {
    try {
      const response = await fetch('/xsd/xrechnung-semantic-model.xsd');
      if (!response.ok) {
        throw new Error('Failed to fetch schema');
      }
      return await response.text();
    } catch (error) {
      console.error('Error loading schema:', error);
      throw new Error('Schema loading failed');
    }
  }

  static async validate(xmlContent: string): Promise<ValidationError[]> {
    const errors: ValidationError[] = [];
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlContent, 'application/xml');

    // Basic XML parsing validation
    const parseErrors = xmlDoc.getElementsByTagName("parsererror");
    if (parseErrors.length > 0) {
      errors.push({
        code: 'PARSE_ERROR',
        message: 'XML-Parsing fehlgeschlagen',
        location: 'document',
        severity: 'CRITICAL'
      });
      return errors;
    }

    // Remove comments and normalize
    const normalizedDoc = this.normalizeXml(xmlDoc);

    // Validate document type
    if (!this.isValidXRechnung(normalizedDoc)) {
      errors.push({
        code: 'INVALID_XRECHNUNG',
        message: 'Die Datei entspricht nicht dem XRechnung-Format',
        location: 'document',
        severity: 'CRITICAL',
        suggestion: 'Bitte stellen Sie sicher, dass die Datei eine gültige XRechnung ist.'
      });
      return errors;
    }

    // Continue with schema validation...
    try {
      const schemaContent = await this.getSchema();
      const validationResult = XMLValidator.validate(xmlContent, {
        allowBooleanAttributes: true,
        preserveOrder: true,
        validateXML: true,
        xsdFilePath: schemaContent
      });

      if (validationResult !== true) {
        const schemaErrors = Array.isArray(validationResult) ? validationResult : [validationResult];
        schemaErrors.forEach(error => {
          errors.push({
            code: 'VALIDATION_ERROR',
            message: error.err.msg,
            location: error.err.line ? `Line ${error.err.line}` : 'document',
            severity: 'CRITICAL',
            suggestion: 'Bitte überprüfen Sie die XML-Struktur gemäß XRechnung-Schema'
          });
        });
      }
    } catch (error) {
      errors.push({
        code: 'PROCESSING_ERROR',
        message: 'Schema-Validierung fehlgeschlagen',
        location: 'document',
        severity: 'CRITICAL',
        suggestion: 'Interner Fehler bei der Schema-Validierung'
      });
    }

    return errors;
  }
} 